@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/avenir-roman-400-webfont.woff2') format('woff2'),
    url('/assets/fonts/avenir-roman-400-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/avenir-medium-500-webfont.woff2') format('woff2'),
    url('/assets/fonts/avenir-medium-500-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/avenir-heavy-900-webfont.woff2') format('woff2'),
    url('/assets/fonts/avenir-heavy-900-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tiempos Headline';
  src: url('/assets/fonts/tiemposheadline-semibold-700-webfont.woff2')
      format('woff2'),
    url('/assets/fonts/tiemposheadline-semibold-700-webfont.woff')
      format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@import '@fontsource/roboto-condensed/700.css';
