// We're defining CSS variables here, so that we can access these
// from less, scss and ts files, plus we can change their values
// based on the current theme without overriding custom CSS

:root {
  // Base Colors ------------------------------------
  --c-red-1: #{$red-1};
  --c-red-2: #{$red-2};
  --c-red-3: #{$red-3};
  --c-red-4: #{$red-4};
  --c-red-5: #{$red-5};

  --c-blue-1: #{$blue-1};
  --c-blue-2: #{$blue-2};
  --c-blue-3: #{$blue-3};
  --c-blue-4: #{$blue-4};
  --c-blue-5: #{$blue-5};
  --c-blue-6: #{$blue-6};
  --c-blue-7: #{$blue-7};

  --c-green-1: #{$green-1};
  --c-green-2: #{$green-2};
  --c-green-3: #{$green-3};
  --c-green-4: #{$green-4};

  --c-purple-1: #{$purple-1};
  --c-purple-2: #{$purple-2};
  --c-purple-3: #{$purple-3};
  --c-purple-4: #{$purple-4};

  --c-teal-1: #{$teal-1};
  --c-teal-2: #{$teal-2};
  --c-teal-3: #{$teal-3};
  --c-teal-4: #{$teal-4};

  --c-gray-0: #{$gray-0};
  --c-gray-1: #{$gray-1};
  --c-gray-2: #{$gray-2};
  --c-gray-3: #{$gray-3};
  --c-gray-4: #{$gray-4};
  --c-gray-5: #{$gray-5};
  --c-gray-6: #{$gray-6};
  --c-gray-7: #{$gray-7};
  --c-gray-8: #{$gray-8};
  --c-gray-9: #{$gray-9};
  --c-gray-10: #{$gray-10};

  // Theme colors -----------------------------------
  // More descriptive color variables
  // that could be altered by the given theme
  --c-primary: #{$red-1};
  --c-accent: #{$blue-2};
  --c-white: #{$white};
  --c-black: #{$black};

  --c-success: #{$success-green};
  --c-warning: #{$warning-orange};
  --c-warning-yellow: #{$warning-yellow};
  --c-error: #{$error-red};

  --c-page-bg: var(--c-white);
  --c-toolbar-bg: var(--c-white);
  --c-right-drawer-bg: var(--c-white);
  --c-border: var(--c-gray-6);
  --c-border-light: var(--c-gray-7);
  --c-disabled: var(--c-gray-7);
  --c-overlay-bg: rgba(255, 255, 255, 0.8);

  --c-text-base: var(--c-gray-3);
  --c-text-heading: var(--c-gray-1);
  --c-text-heading-secondary: var(--c-gray-2);
  --c-text-label: var(--c-gray-1);
  --c-text-input: var(--c-gray-1);
  --c-text-primary-contrast: var(--c-white);

  .dark-theme {
    --c-primary: #{$blue-5};

    --c-page-bg: var(--c-gray-0);
    --c-toolbar-bg: var(--c-gray-2);
    --c-right-drawer-bg: var(--c-gray-1);
    --c-border: var(--c-gray-3);
    --c-border-light: var(--c-gray-2);
    --c-disabled: var(--c-gray-4);
    --c-overlay-bg: rgba(0, 0, 0, 0.6);

    --c-text-base: var(--c-gray-6);
    --c-text-heading: var(--c-white);
    --c-text-heading-secondary: var(--c-gray-9);
    --c-text-label: var(--c-white);
    --c-text-input: var(--c-white);
    --c-text-primary-contrast: var(--c-gray-3);
  }

  // Fonts Faces ------------------------------------
  --ff-heading: #{$ff-heading};
  --ff-base: #{$ff-base};
  --ff-marketing-heading: #{$ff-marketing-heading};
  --ff-special: #{$ff-special};

  // Font Weights -----------------------------------
  --fw-normal: #{$fw-normal}; // 400
  --fw-medium: #{$fw-medium}; // 500
  --fw-semibold: #{$fw-semibold}; // 700
  --fw-bold: #{$fw-bold}; // 900

  // Font sizes -------------------------------------
  --fs-1: #{$fs-1};
  --fs-2: #{$fs-2};
  --fs-3: #{$fs-3};
  --fs-4: #{$fs-4};
  --fs-5: #{$fs-5};

  --fs-h1: #{$fs-h1};
  --fs-h2: #{$fs-h2};
  --fs-h3: #{$fs-h3};
  --fs-h4: #{$fs-h4};
  --fs-h5: #{$fs-h5};

  --fs-base: var(--fs-2);

  // Spacing Sizes ----------------------------------
  --s-1: #{$s-1};
  --s-2: #{$s-2};
  --s-3: #{$s-3};
  --s-4: #{$s-4};
  --s-5: #{$s-5};
  --s-6: #{$s-6};
  --s-6-5: #{$s-6-5};
  --s-7: #{$s-7};
  --s-8: #{$s-8};

  // Custom sizes
  --s-top-bar-height: 50px;
  --s-left-drawer-width: 212px;
  --s-right-toolbar-width: 48px;
  --s-right-drawer-width: 240px;
  --s-whiteboard-toolbar-height: 44px;

  --s-drawer-header-height: 54px;
  --s-drawer-subheader-height: 48px;

  --standard-transition-speed: 280ms;
}
