// This file contains minor overrides of the Material elements
// to better suit our Design System, supporting all themes without duplicating code
// More information about theming components can be found here:
// https://v13.material.angular.io/guide/theming-your-components

@use 'sass:map';
@use '@angular/material' as mat;

@import 'variables';

// Menu
@mixin tweak-menu-color($theme) {
  $config: mat.m2-get-color-config($theme);
  $is-dark: map-get($config, is-dark);

  .mat-mdc-menu-content {
    background-color: if($is-dark, $gray-4, $white);
    color: if($is-dark, $white, $gray-3);
  }
  .mat-mdc-menu-item-text {
    color: if($is-dark, $white, $gray-3);
  }

  .mat-mdc-menu-item:hover:not([disabled]),
  .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
  .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
  .mat-mdc-menu-item-highlighted:not([disabled]) {
    @if $is-dark {
      background: $gray-5;
      color: $white;
    } @else {
      background: $gray-7;
      color: $gray-2;
    }
  }
}

@mixin tweak-select-color($theme) {
  $config: mat.m2-get-color-config($theme);
  $is-dark: map-get($config, is-dark);

  .mat-mdc-select-panel {
    background-color: if($is-dark, $gray-4, $white) !important;

    .mat-mdc-option {
      .mdc-list-item__primary-text {
        color: if($is-dark, $white, $gray-3) !important;
      }

      &:hover {
        background: if($is-dark, $gray-5, $gray-7);
      }
    }
  }
}

@mixin tweak-expansion-theme($theme) {
  .mat-expansion-panel {
    &.documentation-appointment-item {
      background: var(--c-gray-1) !important;
      border-color: var(--c-gray-4);
      border-style: solid;
      border-width: 1px 0px 1px 0px;
      border-radius: 0px;
      .mat-expansion-panel-header {
        font-size: 14px;
        padding: var(--s-3);
        .mat-icon {
          line-height: 1;
          font-size: 36px;
          height: 36px;
          width: 36px;
        }
        &.mat-expanded {
          height: 48px;
        }
      }
      .mat-expansion-panel-body {
        padding: 0px;
      }
    }
  }
}

@mixin tweak-menu-theme($theme) {
  @include tweak-menu-color($theme);

  .mdc-list-item__primary-text {
    font-size: 14px !important;
  }
  .mat-mdc-menu-panel.mat-mdc-menu-panel {
    &.narrow-menu {
      min-width: auto;
    }

    .slider-container {
      width: 160px;
      display: flex;
      padding-left: 16px;
    }

    // compact-menu-panel is currently used for the sort menu in queues list
    &.compact-menu-panel {
      margin-left: 50px;
    }

    &.short-menu {
      .mat-mdc-menu-content {
        height: 300px;
      }
    }

    &.pl-menu-panel {
      width: 216px !important;
      border-radius: 0px;
      .mat-mdc-menu-content {
        padding: 0px !important;
        .pl-menu-content {
          background-color: var(--c-gray-1);
          border-color: var(--c-gray-6);
          border-style: solid;
          border-width: 0px 1px 1px 1px;
          border-radius: 0px 0px 4px 4px;
          .selected-menu-item {
            background-color: var(--c-gray-3);
          }
          .custom-select-options-container {
            color: var(--c-gray-6);
            max-height: 200px;
            overflow-y: auto;
            .mat-mdc-list-item {
              overflow: hidden;
              padding: 0px;
              .mdc-list-item__content {
                height: 44px;
              }

              .mat-mdc-checkbox.custom-check {
                &.mat-mdc-checkbox-checked {
                  .mdc-checkbox .mdc-checkbox__background {
                    background-color: var(--c-blue-6) !important;
                    .mdc-checkbox__checkmark-path {
                      stroke: black !important;
                    }
                  }
                }
                .mdc-checkbox .mdc-checkbox__background {
                  border-color: var(--c-gray-5) !important;
                }
                label {
                  font-weight: var(--fw-normal);
                  color: var(--c-gray-6) !important;
                  font-size: var(--fs-2) !important;
                  white-space: normal;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
              }
            }
          }
          .button-container {
            display: flex;
            flex-direction: column;
            border-top: 1px solid var(--c-gray-6);
            padding: 6px;
            gap: 6px;
            button {
              display: flex;
              align-items: center;
              justify-content: space-around;
              color: var(--c-gray-2);
              width: 100%;
              border-radius: 4px;
            }
            .open-button {
              color: var(--blue-blue-5, #82c1ff);
            }
            .done-button {
              background: var(--blue-blue-5, #82c1ff);
            }
          }
        }
      }
    }

    a.mat-menu-item {
      text-decoration: none;
    }

    &.user-menu.custom-padding {
      .mat-menu-content {
        padding: 0;
      }
    }
  }
}

@mixin tweak-button-toggle-color($theme) {
  $config: mat.m2-get-color-config($theme);
  $is-dark: map-get($config, is-dark);

  @if $is-dark {
    .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
    .mat-button-toggle-group-appearance-standard {
      border-color: $gray-6;

      &:disabled {
        border-color: $gray-4;
      }
    }
    .mat-button-toggle-group-appearance-standard {
      .mat-button-toggle + .mat-button-toggle {
        border-left-color: $gray-6;
      }
      .mat-button-toggle-appearance-standard {
        &.mat-button-toggle-disabled {
          border-color: $gray-4;
        }
      }
      &.mat-button-toggle-disabled {
        .mat-button-toggle + .mat-button-toggle {
          border-color: $gray-4;
        }
      }
    }
    .mat-button-toggle-appearance-standard {
      background-color: transparent;
      &:not(.mat-button-toggle-disabled) {
        color: $gray-6;
      }
      &.mat-button-toggle-disabled {
        background-color: transparent;
      }
      &.mat-button-toggle-checked {
        background-color: $blue-5;
        color: $gray-3;
      }
    }
  }
}

@mixin tweak-button-toggle-theme($theme) {
  @include tweak-button-toggle-color($theme);
  .mat-button-toggle-group {
    .mat-button-toggle {
      flex: 1;
    }
  }
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 36px;
    font-weight: $fw-bold;
    padding: 0 var(--s-2) !important;
  }
}

// Form Field
@mixin tweak-form-field-color($theme) {
  $config: mat.m2-get-color-config($theme);
  $is-dark: map-get($config, is-dark);

  // Updates border, label and hint colors instead of relying on default rgba values
  .mat-mdc-form-field-appearance-outline .mat-form-field-outline,
  .mat-mdc-form-field-appearance-outline .mat-hint,
  .mat-mdc-form-field-appearance-outline .mat-form-field-label {
    color: if($is-dark, $gray-6, $gray-5);
  }
}

@mixin tweak-form-field-theme($theme) {
  @include tweak-form-field-color($theme);

  // design requires this label to be 1 pixel higher than the default. just adding margin or
  // padding to the label doesn't work, but adding this invisible 2 pixel border
  // nudges it up just the right amount.
  .mdc-notched-outline__notch {
    label {
      border-bottom: 2px solid rgba(0, 0, 0, 0);
    }
  }
}

// Tab Group
@mixin tweak-tab-group-color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $is-dark: map.get($color-config, is-dark);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');

  .mat-tab-labels .mat-tab-label {
    color: $gray-6;
    opacity: 1;
  }

  .mat-tab-group.mat-primary {
    .mat-tab-label.mat-tab-label-active {
      color: mat.m2-get-color-from-palette($primary-palette, 500);
    }
  }

  .mat-tab-group.mat-accent {
    .mat-tab-label.mat-tab-label-active {
      color: mat.m2-get-color-from-palette($accent-palette, 500);
    }
  }

  .mat-tab-header {
    border-bottom-color: if($is-dark, $gray-3, $gray-6);
  }

  .mat-tab-body {
    background: if($is-dark, $gray-2, transparent);
  }
}

@mixin tweak-tab-group-theme($theme) {
  @include tweak-tab-group-color($theme);

  .mat-mdc-tab-group,
  .mat-mdc-tab-nav-bar {
    .mat-mdc-tab-header {
      border-bottom: 1px solid var(--c-gray-6);
    }
    .mat-mdc-tab-label-container,
    .mat-mdc-tab-link-container {
      .mdc-tab-indicator__content {
        border-radius: 4px 4px 0 0;
        border-top-width: 4px;
      }
    }
    &.is-compact {
      &:not(.light) {
        .mat-mdc-tab-header {
          border-bottom: 1px solid var(--c-gray-3);
        }
        .mat-mdc-tab-label-container .mdc-tab__text-label {
          color: var(--c-gray-6);
        }
      }
      .mat-mdc-tab-label-container {
        .mat-mdc-tab-labels {
          padding: 0 var(--s-2);
          gap: var(--s-2);
        }

        .mdc-tab {
          padding: 0;
          width: 48px;
          min-width: 48px;
          font-size: var(--fs-2);
          font-weight: var(--fw-medium);
        }
        .mdc-tab__text-label {
          padding-bottom: var(--s-2);
        }

        .mdc-tab__content {
          margin-top: var(--s-3);
        }
        .mdc-tab-indicator__content {
          border-radius: 3px 3px 0 0;
          border-top-width: 3px;
        }
      }
    }
    &.three-tabs {
      .mat-tab-labels {
        padding: 0 var(--s-1);
      }
    }
    &.light {
      .mdc-tab__text-label {
        color: var(--c-gray-5);
      }
    }
  }
}

// Buttons
@mixin tweak-button-color($theme) {
  $config: mat.m2-get-color-config($theme);
  $is-dark: map.get($config, is-dark);
  .mat-mdc-outlined-button:not(:disabled) {
    border-color: currentColor !important;
  }

  .mat-primary:not(:disabled) {
    &.mat-mdc-unelevated-button,
    &.mat-mdc-raised-button,
    &.mdc-fab {
      .mdc-button__label,
      .mat-icon {
        color: if($is-dark, $gray-2, $white);
      }
    }
  }

  // Adds support for new color: 'success'
  .mat-success:not(:disabled) {
    &.mat-mdc-unelevated-button,
    &.mat-mdc-raised-button,
    &.mat-mdc-fab,
    &.mat-mdc-mini-fab {
      background-color: var(--c-success);
      .mdc-button__label,
      .mat-icon {
        color: $white;
      }
    }

    &.mat-mdc-button,
    &.mat-mdc-icon-button,
    &.mat-mdc-outlined-button {
      color: var(--c-success);
      .mat-button-focus-overlay {
        background-color: var(--c-success);
      }
    }
  }
}

@mixin tweak-button-theme($theme) {
  @include tweak-button-color($theme);

  .mat-mdc-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-outlined-button,
  .mat-mdc-raised-button {
    min-height: 44px;

    &.is-rounded {
      border-radius: 44px;
    }

    &.is-small {
      min-height: 28px;
      font-size: var(--fs-2);
      line-height: 1em;
    }
  }
  .mdc-button .mdc-button__label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    white-space: nowrap;
  }

  .pl-menu-button {
    &.mat-mdc-button {
      padding: 0 var(--s-3) 0 var(--s-4);
    }
    .mdc-button__label {
      width: 100%;
      justify-content: space-between;
    }
  }

  .mdc-button .mat-icon {
    height: 24px !important;
    width: 24px !important;
    font-size: 24px !important;
  }
}

// Slide Toggle
@mixin tweak-slide-toggle-color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $is-dark: map.get($color-config, is-dark);
  $primary-palette: map.get($color-config, 'primary');
  $foreground: map.get($color-config, foreground);

  .mat-mdc-slide-toggle .mdc-switch--disabled {
    opacity: 1 !important;

    & + label {
      color: mat.m2-get-color-from-palette($foreground, disabled) !important;
    }
  }

  .mdc-switch + label {
    color: if($is-dark, $gray-6, currentColor);
  }

  // If color isn't provided we're assigning gray color to treat it as 'default'
  .mat-mdc-slide-toggle:not([color], [disabled]) {
    .mdc-switch__handle::before,
    .mdc-switch__handle::after {
      background: if($is-dark, $gray-5, $gray-5) !important;
    }
    .mdc-switch__track::before,
    .mdc-switch__track::after {
      background: if($is-dark, $gray-6, $gray-6) !important;
    }

    .mat-ripple-element {
      background-color: $gray-6;
    }
  }
}

@mixin tweak-checkbox-theme($theme) {
  .mat-mdc-checkbox .mdc-checkbox__ripple {
    display: none !important;
    opacity: 0 !important;
  }
  .mat-mdc-checkbox-ripple {
    display: none !important;
    opacity: 0 !important;
  }
}

@mixin tweak-slide-toggle-theme($theme) {
  @include tweak-slide-toggle-color($theme);
  .mdc-switch + label {
    font-weight: $fw-semibold !important;
    margin-left: 6px !important;
  }

  .mdc-switch {
    &__icons {
      display: none;
    }
  }
}

// Radio Button
@mixin tweak-radio-button-color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $is-dark: map.get($color-config, is-dark);
  .mat-mdc-radio-button
    .mdc-radio:not(.mdc-radio--disabled)
    .mdc-radio__native-control:not(:enabled:checked)
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: if($is-dark, $gray-5, $gray-4) !important;
  }

  .mdc-radio:not(.mdc-radio--disabled) + label {
    color: if($is-dark, $gray-6, $gray-4);
    cursor: pointer;
  }

  .mdc-radio--disabled {
    & + label {
      color: if($is-dark, $gray-4, $gray-6) !important;
    }

    .mdc-radio__outer-circle,
    .mdc-radio__inner-circle {
      border-color: if($is-dark, $gray-4, $gray-6) !important;
      opacity: 1 !important;
    }
  }
}

@mixin tweak-radio-button-theme($theme) {
  @include tweak-radio-button-color($theme);

  .mdc-form-field > label {
    font-weight: $fw-normal;
  }

  .mat-mdc-radio-button .mdc-radio {
    padding: 0 4px 0 0 !important;
  }
}

// Slider
@mixin tweak-slider-theme($theme) {
  .mat-ripple.mat-mdc-focus-indicator {
    display: none;
  }
}

// Snack bars
@mixin tweak-snack-bar-color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $is-dark: map.get($color-config, is-dark);

  .mat-snack-bar-container {
    background-color: $gray-3;

    &.snack-bar-is-error {
      background-color: var(--c-error);
    }
  }

  .mat-mdc-snack-bar-container.pl-snackbar-wrapper .mdc-snackbar__surface {
    background-color: transparent;
    box-shadow: none;
  }
}

@mixin tweak-snack-bar-theme($theme) {
  @include tweak-snack-bar-color($theme);

  .mat-snack-bar-container {
    font-weight: $fw-normal;

    &.mat-snack-bar-top {
      margin-top: 80px;
    }
  }
}

// Dialogs
@mixin tweak-dialog-color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $is-dark: map.get($color-config, is-dark);

  .mat-dialog-header {
    border-bottom: 1px solid if($is-dark, $gray-3, $gray-6);
  }

  .mat-dialog-title {
    color: if($is-dark, $white, $gray-1);
  }
}

@mixin tweak-dialog-theme($theme) {
  @include tweak-dialog-color($theme);

  // // This is a custom class, made to simplify styling headers in material dialogs
  .mat-dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .mdc-dialog__title {
      padding-left: 0px;
      margin-bottom: 0;
    }
  }

  .mat-mdc-dialog-container {
    .mat-mdc-dialog-actions {
      min-height: 44px;
      margin-bottom: 0;
      margin-top: var(--s-5);
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

// Tooltips
@mixin tweak-tooltip-theme($theme) {
  .mat-mdc-tooltip {
    .mdc-tooltip__surface {
      font-size: $fs-2;
      line-height: $fs-4;
      padding: 6px 8px;
      max-width: 250px;
    }
  }
}

// Paginator
@mixin tweak-paginator($theme) {
  .pl-table-paginator {
    &.mat-mdc-paginator {
      .mat-mdc-icon-button {
        color: $gray-5;
        width: 44px;
        height: 44px;
        padding: 10px;

        .mat-mdc-button-persistent-ripple,
        .mat-mdc-button-ripple {
          border-radius: $s-1;
        }

        &:active,
        &:hover {
          .mat-mdc-button-persistent-ripple::before {
            opacity: 1;
            background-color: transparent;
          }

          .mat-mdc-paginator-icon {
            position: relative;
            z-index: 20;
          }

          .mat-mdc-button-ripple {
            z-index: 10;
            background-color: $gray-8;
          }
        }
      }

      .mdc-text-field--outlined:not(
          .mdc-text-field--disabled
        ).mdc-text-field--focused
        .mdc-notched-outline__leading,
      .mdc-text-field--outlined:not(
          .mdc-text-field--disabled
        ).mdc-text-field--focused
        .mdc-notched-outline__notch,
      .mdc-text-field--outlined:not(
          .mdc-text-field--disabled
        ).mdc-text-field--focused
        .mdc-notched-outline__trailing {
        border-color: $blue-2;
      }

      .mat-mdc-form-field.mat-primary .mat-mdc-select-arrow {
        transition: transform 225ms;
      }

      .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
        color: $gray-5;
        transform: rotate(180deg);
      }
    }
  }
  .pl-table-paginator-panel {
    .mat-mdc-option.mat-mdc-option-active,
    .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
        .mdc-list-item--disabled
      ) {
      background: $gray-7;
    }
    .mdc-list-item .mdc-list-item__primary-text,
    .mdc-list-item--selected .mdc-list-item__primary-text,
    .mdc-list-item--activated .mdc-list-item__primary-text {
      color: $gray-3;
    }
  }
}

// CDK
@mixin tweak-cdk($theme) {
  .cdk-overlay-container {
    /* Set this to be greater than widgets board */
    z-index: 10010;
    user-select: none;
  }

  html.cdk-global-scrollblock {
    overflow: hidden;
  }
}

@mixin tweak-mat-menu($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .mat-menu-content {
    padding: 0;
  }
  .mat-mdc-menu-panel {
    .mat-mdc-menu-content {
      .mat-mdc-menu-item {
        &.unsafe {
          .mdc-list-item__primary-text {
            color: mat.m2-get-color-from-palette($primary-palette, 500);
          }
        }
      }
    }
  }
}
