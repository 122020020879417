@use '@angular/material' as mat;

@import 'variables';

@mixin split-button-theme($theme) {
  $config: mat.m2-get-color-config($theme);
  $is-dark: map-get($config, is-dark);

  .split-button {
    display: flex;
    justify-content: space-between;
    .mdc-button__label {
      justify-content: flex-start;
    }

    // main action
    .left {
      width: 100%;
      margin-right: 0 !important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &.mat-mdc-outlined-button {
        border-right-width: 0;
      }

      &.mat-mdc-raised-button {
        box-shadow: none;
      }
    }

    // dropdown arrow
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      min-width: auto;
      margin-left: 0 !important;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &.mat-mdc-raised-button {
        box-shadow: none;
      }

      mat-icon {
        margin: 0;
      }
    }

    // :has() isn't supported in Firefox, but it's not a big deal,
    // in that case split button will just lack the box-shadow, everything else will be the same
    @supports selector(:has(*)) {
      &:has(.mat-mdc-raised-button) {
        border-radius: 4px;
        transition: box-shadow 280ms;
        box-shadow:
          0px 3px 1px -2px rgb(0 0 0 / 20%),
          0px 2px 2px 0px rgb(0 0 0 / 14%),
          0px 1px 5px 0px rgb(0 0 0 / 12%);

        &:active {
          box-shadow:
            0px 5px 5px -3px rgb(0 0 0 / 20%),
            0px 8px 10px 1px rgb(0 0 0 / 14%),
            0px 3px 14px 2px rgb(0 0 0 / 12%);
        }
      }

      &:has(.mat-mdc-raised-button[disabled]) {
        box-shadow: none !important;
      }
    }
  }
}

@mixin slide-toggle-group-theme($theme) {
  $config: mat.m2-get-color-config($theme);
  $is-dark: map-get($config, is-dark);

  .mat-slide-toggle-group {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > label {
      font-weight: var(--fw-normal);
    }
  }
}

@mixin chips-theme($theme) {
  .chip-transparent {
    background-color: transparent !important;
    border: 1px solid $gray-5;
  }
}

@mixin radio-bg-button-theme($theme) {
  $config: mat.m2-get-color-config($theme);
  $is-dark: map-get($config, is-dark);

  .radio-bg-button {
    &:hover {
      color: var(--c-white);

      .radio-bg-label {
        background-color: var(--c-gray-3);
      }
    }

    .mdc-form-field {
      display: flex;
    }

    label {
      flex: 1;
    }

    .mdc-radio--disabled {
      pointer-events: none;

      & ~ label {
        filter: grayscale(100%);
      }
    }

    .radio-bg-label {
      display: flex;
      align-items: center;
      flex: 1;
      padding: var(--s-1);
      gap: var(--s-3);
      font-weight: var(--fw-bold);
      white-space: normal;
      border-radius: var(--s-1);
    }

    .radio-bg-preview {
      width: 60px;
      height: 60px;
      border-radius: var(--s-1);
      object-fit: cover;
      background-color: var(--c-border);
    }
  }
}

@mixin library-tabs-theme($theme) {
  .library-tab-nav-bar {
    a.library-tab-link {
      text-decoration: none;

      &:focus {
        outline: 0rem;
        box-shadow: none;
      }
    }
  }
}

@mixin mat-icon-size($button-size, $icon-size) {
  width: $button-size !important;
  height: $button-size !important;
  padding: 0 !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  & > *[role='img'] {
    width: $icon-size;
    height: $icon-size;
    font-size: $icon-size;

    svg {
      width: $icon-size;
      height: $icon-size;
    }
  }

  .mat-mdc-button-touch-target {
    width: $button-size !important;
    height: $button-size !important;
  }
}

@mixin a-hyperlink-theme($theme) {
  $config: mat.m2-get-color-config($theme);
  $is-dark: map-get($config, is-dark);

  a.light {
    display: inline-flex;
    gap: 2px;
    color: $blue-3;
  }
}

@mixin mat-icon-backwards() {
  .mat-icon.backwards {
    transform: rotateY(180deg) rotateX(180deg);
  }
}

@mixin mat-dialog-theme($theme) {
  .pl-default-dialog {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

    &--header {
      padding: $s-3 $s-5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        color: $gray-1;
        flex: 1 0 0;
        font-size: $fs-h2;
        font-weight: 500;
        font-style: normal;
        line-height: 28px;
        padding: 0;

        &::before {
          display: none;
        }
      }
    }

    &--divider {
      height: 1px;
      flex-shrink: 0;
      align-self: stretch;
      background-color: $gray-6;
      margin: 5px 24px;
    }

    &--content {
      padding: $s-3 $s-5;
      color: $gray-3 !important;
    }

    &--actions {
      margin: 0 !important;
      padding: $s-3 $s-5 !important;
    }
  }
}

@mixin mat-menu-theme($theme) {
  .mat-mdc-menu-panel.pl-default-actions-menu {
    .mat-mdc-menu-content {
      padding: 0px;
      hr {
        margin: 0px;
      }
      mat-icon {
        color: var(--c-gray-5) !important;
        font-size: 20px;
        height: 20px;
        width: 20px;
        margin-right: 4px;
        &.red-icon {
          color: var(--c-red-1) !important;
        }
      }
      .submenu-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        mat-icon {
          margin-right: 0px;
        }
      }
      .mat-mdc-menu-submenu-icon {
        display: none !important;
      }
      .mat-mdc-menu-item-text {
        font-size: 14px !important;
        color: var(--c-gray-3) !important;
      }
    }
  }
}

@mixin new-feature-label-theme($theme) {
  $config: mat.m2-get-color-config($theme);
  $is-dark: map-get($config, is-dark);

  .new-feature-label {
    display: inline-flex;
    height: 18px;
    padding: 2px 6px;
    align-items: center;
    gap: 10px;
    border-radius: 100px;

    @if $is-dark {
      background: $blue-3;
      color: $white;
    } @else {
      background: $yellow-1;
      color: $black;
    }
    font-family: var(--ff-base);
    font-size: var(--fs-1);
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-transform: none;
  }
}
