// TODO: Consider updating main layout to utilize available area
// instead of calculating the exact location of the central content
.main-view {
  user-select: none;
  --central-content-top: var(--s-top-bar-height);
  --central-content-right: var(--s-right-toolbar-width);
  --central-content-bottom: 0;
  --central-content-left: var(--s-left-drawer-width);

  &.student-mode {
    --central-content-top: 0;
  }

  &.right-drawer-open {
    --central-content-right: calc(
      var(--s-right-toolbar-width) + var(--s-right-drawer-width)
    );
  }

  &.jumbotron-mode,
  &.bradybunch-mode {
    --central-content-left: 0;
  }
}

.central-content {
  position: absolute;
  top: var(--central-content-top);
  right: var(--central-content-right);
  bottom: var(--central-content-bottom);
  left: var(--central-content-left);
  transition: all 300ms;
  background-color: @gray !important;
  background-image: none !important;

  &.ng-enter {
    opacity: 0;
    transition: opacity 200ms 300ms;
    &.ng-enter-active {
      opacity: 1;
    }
    &.detail-view {
      opacity: 1;
      transition: transform 250ms;
      transform: translate3d(100%, 0, 0);
      &.ng-enter-active {
        transform: translate3d(0, 0, 0);
      }
    }
  }
  &.ng-leave {
    opacity: 1;
    transition: opacity 200ms;
    &.ng-leave-active {
      opacity: 0;
    }
    &.detail-view {
      opacity: 1;
      transition: transform 250ms 200ms;
      transform: translate3d(0, 0, 0);
      &.ng-leave-active {
        transform: translate3d(100%, 0, 0);
      }
    }
  }

  header.toolbar,
  footer.toolbar {
    position: relative;
    width: 100%;
    height: 44px;
    padding: 0 11px;
    min-width: 0;
    background-color: lighten(@gray-lightest, 4%);
  }
  header.toolbar {
    border-bottom: 1px solid @gray-lighter;
  }
  footer.toolbar {
    position: absolute;
    top: auto;
    left: 0;
    bottom: 0;
    border-top: 1px solid @gray-lighter;
  }
}
