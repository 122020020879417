@text-color: @gray-darkest;
@headings-color: @gray-darkest;
@link-color: @blue;
@font-family: 'Avenir';
@headings-font-family: 'Avenir';
@headings-font-weight: 300;
@font-size-h1: 28px;
@font-size-h2: 22px;
@font-size-h3: 16px;
@font-size-h4: 16px;
@font-size-h5: 16px;
@font-size-h6: 14px;

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  strong {
    font-weight: 400;
  }
  b {
    font-weight: 700;
  }
  &.header {
    background-color: @slate-light;
    padding: 14px;
  }
}

h1,
.font-h1 {
  font-size: @font-size-h1;
  line-height: 32px;
  letter-spacing: 0.25px;
}
h2,
.font-h2 {
  font-size: @font-size-h2;
  line-height: 28px;
  letter-spacing: 0.25px;
}
h3,
.font-h3 {
  font-size: @font-size-h3;
  line-height: 42px;
  letter-spacing: 0.25px;
}
h4,
.font-h4 {
  font-size: @font-size-h4;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 2px;
}
h5,
.font-h5 {
  font-size: @font-size-h5;
  line-height: 30px;
  letter-spacing: 2px;
}
h6,
.font-h6 {
  font-size: @font-size-h6;
  line-height: 20px;
  letter-spacing: 0.15px;
}
.bold {
  font-weight: bold;
}

p {
  font-family: @font-family;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
}

p.medium {
  font-size: 16px;
}

button {
  font-family: @font-family;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}

a,
.a-link {
  font-family: @font-family;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: @blue-2;
  text-decoration: none;
  cursor: pointer;

  &:focus {
    box-shadow: inset 0px 0px 0px 1px @blue-medium;
  }

  &:hover {
    text-decoration: underline;
  }

  &.link-no-color {
    color: inherit;
  }

  &.link-underline {
    text-decoration: underline;
  }

  &.link-unstyled {
    &:hover {
      text-decoration: none;
    }
  }

  &.link-none {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

.pointer {
  cursor: pointer;
}
