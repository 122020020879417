// Custom overrides for external libraries, or legacy hacks go here:
@import 'variables';

ngx-skeleton-loader {
  .skeleton-loader.progress {
    box-shadow: unset;
    display: block;
    margin-bottom: 0;
    background: $gray-7 no-repeat;
  }
}
