// This file contains base global styles for the application
// it shouldn't however contain global overrides of classes that belong
// to 3rd party libraries or internal components

html {
  font-family: var(--ff-base);
  color: var(--c-text-base);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--c-page-bg);

  // TODO: Enable once we get rid of Bootstrap and other global styles
  // that might be overriding it
  // font-size: 62.5%; // Ensures 1rem is 10px
}

body {
  font-family: var(
    --ff-base
  ) !important; // Force default font to ensure bootstrap doesn't override it

  // TODO: Enable once we get rid of Bootstrap and other global styles
  // that might be overriding it
  // font-size: var(--fs-base);
}

*,
*:before,
*:after {
  &:not(text) {
    font-family: inherit;
  }
  box-sizing: border-box;
  margin: 0;
}

.heading-1 {
  @include heading-1();
}

.heading-2 {
  @include heading-2();
}

.heading-3 {
  @include heading-3();
}

.heading-4 {
  @include heading-4();
}

.heading-5 {
  @include heading-5();
}

.subheading {
  @include subheading();
}

[role='button']:not([disabled]) {
  cursor: pointer;
}

::selection,
::-moz-selection {
  color: $gray-3 !important;
  background: $blue-6 !important;
}

.dark-theme {
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background-color: $gray-1;
    border-left: 1px solid $gray-4;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $gray-6;
    border-radius: 15px;
    border: 2px solid rgba(0, 0, 0, 0);
    border-left-width: 3px;
    background-clip: padding-box;
  }

  // trick for Firefox
  @supports (scrollbar-color: $gray-6 $gray-1) {
    * {
      scrollbar-color: $gray-6 $gray-1;
      scrollbar-width: thin;
    }
  }
}
