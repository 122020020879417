$_directions: (
  top: 't',
  right: 'r',
  bottom: 'b',
  left: 'l',
);

// Generates size classes .s-1, .s-2 etc.
@for $i from 1 through 8 {
  .s-#{$i} {
    @include sizing(var(--s-#{$i}));
  }
}

// TODO: Enable when replacing currently used utility classes
// Generates .m-1, .m-2 etc.
@for $i from 1 through 8 {
  .m-#{$i} {
    margin: var(--s-#{$i});
  }
}

// Generates .mt-1, .mb-2, .ml-3, .mr-4 etc.
@each $dir, $prefix in $_directions {
  @for $i from 1 through 8 {
    .m#{$prefix}-#{$i} {
      margin-#{$dir}: var(--s-#{$i});
    }
  }
}

// Generates .p-1, .p-2 etc.
@for $i from 1 through 8 {
  .p-#{$i} {
    padding: var(--s-#{$i});
  }
}

// Generates .pt-1, .pb-2, .pl-3, .pr-4 etc.
@each $dir, $prefix in $_directions {
  @for $i from 1 through 8 {
    .p#{$prefix}-#{$i} {
      padding-#{$dir}: var(--s-#{$i});
    }
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.text-center {
  text-align: center;
}

.bold {
  font-weight: bold;
}
