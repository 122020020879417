// TODO: Remove once we no longer depend on lib-components
@import '../node_modules/bootstrap/less/bootstrap';

// TODO: Remove lib-components styles once we no longer depend on lib-components

@import './lib-components/core/reset.less';
@import './lib-components/core/accessibility.less';
@import './lib-components/core/colors.less';
@import './lib-components/core/shapes.less';
@import './lib-components/core/colors-classes.less';
@import './lib-components/core/bootstrap-parts.less';

@import './lib-components/core/decoration.less';

@import './lib-components/core/flexbox.less';
@import './lib-components/core/layout.less';
@import './lib-components/core/spacing.less';

// TODO: Move following global stuff to src/styles & use CSS variables
@import './app/common/less/colors';
@import './app/common/less/typography';
@import './app/common/less/global';
@import './app/common/less/centralContent';

// TODO: Remove once we no longer depend on lib-components
@import './app/common/less/pl-comps-override.less';

// TODO: Move to src/styles/overrides
@import './app/common/less/intro-js-overrides.less';

// Libraries styles
@import './app/modules/team-write/firepad.css';
