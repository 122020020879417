.dot(@color) {
    background-color: @color;
    display: inline-block;
    width: 11px;
    height: 11px;
    border-radius: 11px;
}

.orange-dot {
    .dot(@orange);
}

.yellow-dot {
    .dot(@yellow);
}

.yellow-dark-dot {
    .dot(@yellow-dark);
}

.green-dot {
    .dot(@green);
}

.blue-dot {
    .dot(@blue);
}

.gray-dot {
    .dot(@gray);
}

.green-medium-dot {
    .dot(@green-medium);
}