.flexbox {
    display: flex;
}
.flex1 {
    flex: 1;
}
.flex2 {
    flex: 2;
}
.flex3 {
    flex: 3;
}
.flex-start {
    justify-items: flex-start;
}
