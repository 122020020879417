@mixin _position($position, $args) {
  @each $dir in top, left, bottom, right {
    $i: index($args, $dir);

    @if $i {
      #{$dir}: nth($args, $i + 1);
    }
  }

  position: $position;
}

@mixin absolute($args) {
  @include _position(absolute, $args);
}

@mixin relative($args) {
  @include _position(relative, $args);
}

@mixin fixed($args) {
  @include _position(fixed, $args);
}

@mixin sizing($args, $prefix: '') {
  $width: if(length($args) ==2, nth($args, 1), $args);
  $height: if(length($args) ==2, nth($args, 2), $args);

  #{$prefix}width: $width;
  #{$prefix}height: $height;
}

@mixin circle($args) {
  @include sizing($args);

  border-radius: 50%;
}

@mixin flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin before() {
  &::before {
    content: '';

    @content;
  }
}

@mixin after() {
  &::after {
    content: '';

    @content;
  }
}

@mixin heading($font-size, $font-weight: var(--fw-medium)) {
  color: var(--c-text-heading);
  font-family: var(--ff-heading);
  font-weight: $font-weight;
  font-size: $font-size;
  margin: 0;
}

@mixin heading-1() {
  @include heading(var(--fs-h1));
  letter-spacing: 0.25px;
  line-height: 32px;
}

@mixin heading-2() {
  @include heading(var(--fs-h2));
  letter-spacing: 0.25px;
  line-height: 28px;
}

@mixin heading-3() {
  @include heading(var(--fs-h3));
  letter-spacing: 0.25px;
  line-height: 24px;
}

@mixin heading-4() {
  @include heading(var(--fs-h4), var(--fw-bold));
  letter-spacing: 2px;
  line-height: 30px;
}

@mixin heading-5() {
  @include heading(var(--fs-h5));
  letter-spacing: 2px;
  line-height: 30px;
}

@mixin subheading() {
  @include heading(var(--fs-2), var(--fw-normal));
  line-height: 20px;
}
