@import './colors.less';

input,
textarea {
  &::placeholder {
    color: @gray !important;
  }
}

// Copied from pl-components
.btn-style
  (@color, @border, @background, @color-hover, @border-hover, @background-hover, @burst-color: lighten(@background, 20%)) {
  color: @color;
  border-color: @border;
  background-color: @background;
  figure svg {
    fill: @color;
  }

  pl-icon {
    fill: @color;
  }

  [burst] {
    background-color: @burst-color;
  }
  &.hover,
  &:hover {
    color: @color-hover;
    border-color: @border-hover;
    background-color: @background-hover;

    pl-icon {
      fill: @color-hover;
    }
  }
}

a,
.a-link {
  color: @blue-lighter;
}

.pl-input-checkbox.disabled {
  input:checked + .icon-container {
    background-color: @gray-dark !important;
  }
  .icon-container,
  input:hover + .icon-container,
  input:focus + .icon-container {
    border-color: @gray-darker !important;
    background-color: @gray-dark !important;
  }
  svg {
    fill: @gray-darker;
  }
}
