.fade-in {
  animation: 280ms linear fadeInTransition;
}

@keyframes fadeInTransition {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
