.color-class (@color) {
    color: @color;
    fill: @color;
}

.blue {
    .color-class(@blue);
}
.blue-medium {
    .color-class(@blue-medium);
}
.blue-light {
    .color-class(@blue-light);
}
.blue-lighter {
    .color-class(@blue-lighter);
}
.blue-lightest {
    .color-class(@blue-lightest);
}
.orange {
    .color-class(@orange);
}
.orange-light {
    .color-class(@orange-light);
}
.orange-dark {
    .color-class(@orange-dark);
}
.green {
    .color-class(@green);
}
.red {
    .color-class(@red);
}
.yellow {
    .color-class(@yellow);
}
.purple {
    .color-class(@purple);
}

.black {
    .color-class(@black);
}
.white {
    .color-class(@white);
}

.gray {
    .color-class(@gray);
}
.gray-darkest {
    .color-class(@gray-darkest);
}
.gray-darker {
    .color-class(@gray-darker);
}
.gray-dark {
    .color-class(@gray-dark);
}
.gray-light {
    .color-class(@gray-light);
}
.gray-lighter {
    .color-class(@gray-lighter);
}
.gray-lightest {
    .color-class(@gray-lightest);
}

.slate {
    .color-class(@slate);
}
.slate-darkest {
    .color-class(@slate-darkest);
}
.slate-light {
    .color-class(@slate-light);
}
.slate-lighter {
    .color-class(@slate-lighter);
}
.slate-lightest {
    .color-class(@slate-lightest);
}